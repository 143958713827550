<template>
    <div class="dynamicDetailWrap">
        <div class="dynamicDetail">
            <div class="breadNav">
                    您的位置：
                    <span @click="goHome">首页</span>
                     ><span @click="godetailList">{{titVal}}</span>
                      ><span>动态详情</span> 
                            
            </div>
            
            <div class="detailMain">
                <div class="detailMain-left">
                    <!-- <div class="tit">{{titVal}}</div> -->
                    <div class="left-listWrap" >
                        <div v-html="arr.content">
                        </div>
                    </div>

                    
                </div>


                <div class="detailMain-right">
                    <div class="tit">{{titVal}}</div>  
                    <div class="rightListWrap">
                        <div class="rightList">
                                <p @click="godetailList">此处为资讯列表页主标题此处为资讯列表页主标题此处为资讯列表...</p>
                                <p @click="godetailList">此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新闻内容  简介此处新闻内容简介此处新闻内容简介此处新闻内容简介此处新..</p>
                                <div>
                                    <span>2019-02-10</span>
                                </div>
                        </div>
                      
                    </div>               
                </div>
            </div>
        </div>
    </div>
   
</template>

<script>

export default {
    name:"dynamicDetail",
    data() {
        
        return {
             page: 1, //当前第几页
            rows: 10, //每页默认显示几条
            totalSize:0, //总条数
            detailId:this.$route.params.detailId,
            arr:[], //详情展示数据
            titVal:"", 
        };
    },
    created() {
        this.getInfo();
        this.judge();
    },
    methods: {
        judge(){
            let classId=this.$route.params.id;
            if (classId==0) {
                this.titVal="最新动态";
            }
            if (classId==1) {
                this.titVal="品牌活动";
            }
        },
        getInfo(){
          this.$http.get('/content/article/detail?id='+this.detailId).then(res => {
            this.arr = res.data.data;
            console.log(this.arr,88)
          })
      },
        goHome(){
            this.$router.push("/");
        },
        godetailList(){
            this.$router.push("/dynamicList");
        },
         //以下分页
        //选择每页显示条数触发
        sizeChangePage(val) {
            let self = this;
            self.rows = val;
            // console.log(`每页 ${val} 条`);
            self.getList(self.rows); //请求接口
        },
        //选择第几页触发
        currentChangePage(val) {
        // console.log(`当前页: ${val}`);
            let self = this;
            self.page = val;
            self.getList(self.page);
        },
    },
    }
</script>
<style lang='scss' scoped>
*{
    margin: 0;
    padding: 0;
}
.dynamicDetailWrap{
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    .dynamicDetail{
        width: 1200px;
        margin: 0 auto;
        // background: deepskyblue;
        .tit{
            font-size: 18px;
            line-height: 44px;
            color: #333;
        }
        .breadNav {
            padding: 10px 0;
            font-size: 14px;
            :nth-child(1), :nth-child(2){
                cursor: pointer;
            }
        }

        .detailMain{
            display: flex;
            justify-content:space-between;
            .detailMain-left{
                width: 780px;
                .left-listWrap{
                background: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding:30px 20px 50px 20px;  
                    margin-top: 10px;
                   /deep/ img{
                       display: block;
                       width: 100%;
                       
                    }
                }

            }


            .detailMain-right{
                width: 390px;
                .rightListWrap{
                    padding: 20px;
                    background: #fff;
                    padding-top: 10px;
                    .rightList{
                         border-bottom: 1px solid #f0f0f0;
                         padding-bottom: 10px;
                         p{
                             cursor: pointer;
                             &:hover{
                                 color: #d82229;
                             }
                         }
                        >:first-child{
                            line-height: 44px;
                            color: #333;
                            margin-bottom: 7px;
                            width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 16px;
                            font-weight: 900;
                        }
                        >:nth-child(2){
                            font-size: 16px;
                            color: #999;
                            line-height:24px; 
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                        }
                        >:nth-child(3){
                            margin-top: 11px;
                            font-size: 16px;
                            color: #999;
                            line-height:26px;
                            
                        }
                     }
                }
            }
        }
    }
}

</style>